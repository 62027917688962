import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService, BaseComponent, BaseComponentMode, ConfirmDialogComponent } from "@impacgroup/angular-baselib";
import { BreakoutGroupsService } from "./breakoutgroups.service";
import { TranslateService } from "@ngx-translate/core";
import { BreakoutGroup } from "../../api-models/BreakoutGroup";
import { BsModalService } from "ngx-bootstrap/modal";
import { BreakoutGroupUser } from "src/app/api-models/BreakoutGroupUser";

@Component({
    selector: "app-breakoutgroupdetail",
    templateUrl: "./breakoutgroupdetail.component.html",
    styleUrls: ["breakoutgroupdetail.component.scss"]
})
export class BreakoutGroupDetailComponent extends BaseComponent implements OnInit {
    private breakoutGroupId: string = null;
    public breakoutGroup: BreakoutGroup = null;

    public breakoutGroups: BreakoutGroup[] = [];
    public allUsers: BreakoutGroupUser[] = [];
    public filteredUsers: BreakoutGroupUser[] = [];

    public showOnlySelectedCurrent = false;
    public showOnlyAssignedCurrent = false;
    public showOnlyUnassigned = false;

    public assignedUserCount = -1;

    constructor(
        private route: ActivatedRoute,
        private breakoutGroupsService: BreakoutGroupsService,
        private router: Router,
        private alertService: AlertService,
        private translateService: TranslateService,
        private _location: Location,
        private modalService: BsModalService
    ) {
        super();

        this.subscriptions.push(
            this.route.params.subscribe((params) => {
                this.breakoutGroupId = params.id;
                if (this.breakoutGroupId) {
                    this.initEditMode();
                } else {
                    this.initAddMode();
                }
            })
        );
    }

    ngOnInit(): void {}

    initAddMode() {
        this.MODE = BaseComponentMode.ADD;
        this.breakoutGroup = new BreakoutGroup();
    }

    initEditMode() {
        this.MODE = BaseComponentMode.EDIT;

        this.subscriptions.push(
            this.breakoutGroupsService.breakoutGroupDetail(this.breakoutGroupId).subscribe(
                (result) => {
                    this.breakoutGroup = result;

                    this.loadBreakoutGroupUsers();
                },
                (err) => {
                    this.router.navigate(["../"], { relativeTo: this.route });
                }
            )
        );
    }

    save() {
        if (this.MODE === BaseComponentMode.ADD) {
            this.subscriptions.push(
                this.breakoutGroupsService.addBreakoutGroup(this.breakoutGroup).subscribe((result) => {
                    this.alertService.addSuccess(this.translateService.instant("breakoutgroups.detail.add.success"));
                    this.router.navigate(["../"], { relativeTo: this.route });
                })
            );
        }

        if (this.MODE === BaseComponentMode.EDIT) {
            this.subscriptions.push(
                this.breakoutGroupsService.updateBreakoutGroup(this.breakoutGroup).subscribe((result) => {
                    this.alertService.addSuccess(this.translateService.instant("breakoutgroups.detail.edit.success"));
                    this.router.navigate(["../"], { relativeTo: this.route });
                })
            );
        }
    }

    back() {
        this._location.back();
    }

    private loadBreakoutGroupUsers() {
        this.breakoutGroupsService.breakoutGroups({}).subscribe((result) => {
            this.breakoutGroups = result.list.sort((a, b) => a.key.localeCompare(b.key));

            this.breakoutGroupsService.getBreakoutGroupUsers(this.breakoutGroup, this.breakoutGroups).subscribe((result) => {
                this.allUsers = result;

                this.filterUsers();
            });
        });
    }

    private filterUsers() {
        this.assignedUserCount = this.allUsers.filter((user) => user.assignedBreakoutGroup && user.assignedBreakoutGroup.key === this.breakoutGroup.key).length;

        this.filteredUsers = this.allUsers.filter((user) => {
            if (this.showOnlySelectedCurrent && !user.selectedBreakoutGroups.map((group) => group.key).includes(this.breakoutGroup.key)) {
                return false;
            }

            if (this.showOnlyAssignedCurrent && (!user.assignedBreakoutGroup || user.assignedBreakoutGroup.key !== this.breakoutGroup.key)) {
                return false;
            }

            if (this.showOnlyUnassigned && user.assignedBreakoutGroup) {
                return false;
            }
            return true;
        });
    }

    changeUserCheckbox(event, user: BreakoutGroupUser) {
        const newCheckboxState = event.target.checked;

        // Prevent toggling checkbox until the modal is answered (or not)
        event.target.checked = !newCheckboxState;
        user.checked = !newCheckboxState;

        if (newCheckboxState === true) {
            this.assignUserToBreakoutGroup(user);
        } else {
            this.deleteBreakoutGroupFromUser(user);
        }
    }

    private assignUserToBreakoutGroup(user: BreakoutGroupUser) {
        // Check if user is already assigned
        if (typeof user?.assignedBreakoutGroup?.key === "undefined") {
            // User is not assigned to any breakout groups
            this.subscriptions.push(
                this.breakoutGroupsService.assignUserToBreakoutGroup(this.breakoutGroup, user).subscribe((result) => {
                    this.alertService.addSuccess(this.translateService.instant("breakoutgroups.detail.assignedUsers.assign.success"));

                    this.loadBreakoutGroupUsers();
                })
            );
        } else {
            // User is already assigned to a different breakout group
            const initialState = {
                title: this.translateService.instant("breakoutgroups.detail.assignedUsers.reassign.title"),
                message: this.translateService.instant("breakoutgroups.detail.assignedUsers.reassign.message"),
                closeBtnName: this.translateService.instant("global.buttons.close"),
                confirmBtnName: this.translateService.instant("global.buttons.ok")
            };
            const bsModalRef = this.modalService.show(ConfirmDialogComponent, { initialState });
            bsModalRef.content.confirmCallback = () => {
                this.subscriptions.push(
                    this.breakoutGroupsService.assignUserToBreakoutGroup(this.breakoutGroup, user).subscribe((result) => {
                        this.alertService.addSuccess(this.translateService.instant("breakoutgroups.detail.assignedUsers.reassign.success"));

                        this.loadBreakoutGroupUsers();
                    })
                );
            };
        }
    }

    private deleteBreakoutGroupFromUser(user: BreakoutGroupUser) {
        const initialState = {
            title: this.translateService.instant("breakoutgroups.detail.assignedUsers.delete.title"),
            message: this.translateService.instant("breakoutgroups.detail.assignedUsers.delete.message"),
            closeBtnName: this.translateService.instant("global.buttons.close"),
            confirmBtnName: this.translateService.instant("global.buttons.ok")
        };
        const bsModalRef = this.modalService.show(ConfirmDialogComponent, { initialState });
        bsModalRef.content.confirmCallback = () => {
            this.subscriptions.push(
                this.breakoutGroupsService.deleteBreakoutGroupFromUser(this.breakoutGroup, user).subscribe((result) => {
                    this.alertService.addSuccess(this.translateService.instant("breakoutgroups.detail.assignedUsers.assign.success"));

                    this.loadBreakoutGroupUsers();
                })
            );
        };
    }
}
