<div class="content__inner">
    <header class="content__title">
        <h1 *ngIf="MODE === 'ADD'">{{ "breakoutgroups.detail.add.title" | translate }}</h1>
        <h1 *ngIf="MODE === 'EDIT'">{{ "breakoutgroups.detail.edit.title" | translate }}</h1>

        <div class="actions">
            <button (click)="back()" class="btn btn-secondary btn--icon-text"><i class="zmdi zmdi-arrow-back"></i> {{ "global.buttons.back" | translate }}</button>
        </div>
    </header>

    <div class="card">
        <div class="card-body">
            <form name="form" class="row" *ngIf="breakoutGroup" (ngSubmit)="f.form.valid && save()" #f="ngForm" novalidate>
                <div class="col-md-12" *ngIf="MODE === 'ADD'">
                    <div class="form-group">
                        <label>{{ "breakoutgroups.props.key" | translate }}</label>
                        <input name="key" type="text" class="form-control" [(ngModel)]="breakoutGroup.key" #key="ngModel" [ngClass]="{ 'is-invalid': f.submitted && key.invalid }" required />
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ "breakoutgroups.detail.validation.key" | translate }}
                        </div>
                    </div>
                </div>

                <div class="col-md-12" *ngIf="MODE !== 'ADD'">
                    <div class="form-group">
                        <label>{{ "breakoutgroups.props.key" | translate }}</label>
                        <input name="key" type="text" class="form-control" [ngModel]="breakoutGroup.key" readonly="readonly" />
                        <i class="form-group__bar"></i>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="form-group">
                        <label>{{ "breakoutgroups.props.name" | translate }}</label>
                        <input name="name" type="text" class="form-control" [(ngModel)]="breakoutGroup.name" #name="ngModel" [ngClass]="{ 'is-invalid': f.submitted && name.invalid }" required />
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ "breakoutgroups.detail.validation.name" | translate }}
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="form-group">
                        <label>{{ "breakoutgroups.props.link" | translate }}</label>
                        <input name="link" type="text" class="form-control" [(ngModel)]="breakoutGroup.link" #link="ngModel" [ngClass]="{ 'is-invalid': f.submitted && link.invalid }" required />
                        <i class="form-group__bar"></i>
                        <div class="invalid-tooltip">
                            {{ "breakoutgroups.detail.validation.link" | translate }}
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <button class="float-right btn btn-success btn--icon-text" [disabled]="f.submitted && f.invalid"><i class="zmdi zmdi-check"></i> {{ "global.buttons.save" | translate }}</button>
                </div>
            </form>
        </div>
    </div>

    <div class="card" *ngIf="MODE === 'EDIT' && allUsers.length > 0">
        <div class="card-body">

            <h4 class="card-title mb-0">{{ "breakoutgroups.detail.assignedUsers.title" | translate }}: {{ assignedUserCount }}</h4>
            <span class="card-subtitle">Shown users: ({{filteredUsers.length }} / {{allUsers.length}})</span>
            
            <div class="actions-padding pb-5"></div>

            <div class="actions">
                <div class="checkbox">
                    <input type="checkbox" id="filter_show_only_selected_current" [(ngModel)]="showOnlySelectedCurrent" (change)="filterUsers()" />
                    <label class="checkbox__label" for="filter_show_only_selected_current">"{{ breakoutGroup.key }}" selected</label>
                </div>
                <div class="checkbox">
                    <input type="checkbox" id="filter_show_only_assigned_current" [(ngModel)]="showOnlyAssignedCurrent" (change)="filterUsers()" />
                    <label class="checkbox__label" for="filter_show_only_assigned_current">"{{ breakoutGroup.key }}" assigned</label>
                </div>
                <div class="checkbox">
                    <input type="checkbox" id="filter_show_only_unassigned" [(ngModel)]="showOnlyUnassigned" (change)="filterUsers()" />
                    <label class="checkbox__label" for="filter_show_only_unassigned">Unassigned users</label>
                </div>
            </div>

            <table class="table mb-0">
                <thead>
                    <tr>
                        <th></th>
                        <th>{{ "breakoutgroups.detail.assignedUsers.props.firstname" | translate }}</th>
                        <th>{{ "breakoutgroups.detail.assignedUsers.props.lastname" | translate }}</th>
                        <th>{{ "breakoutgroups.detail.assignedUsers.props.email" | translate }}</th>
                        <th>{{ "breakoutgroups.detail.assignedUsers.props.selected" | translate }}</th>
                        <th>{{ "breakoutgroups.detail.assignedUsers.props.assigned" | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let user of filteredUsers">
                        <td>                
                            <div class="checkbox">
                                <input type="checkbox" id="checked_{{user._id}}" [checked]="user.checked" (change)="changeUserCheckbox($event, user)" />
                                <label class="checkbox__label" for="checked_{{user._id}}"></label>
                            </div>
                        </td>
                        <td>{{ user?.firstname }}</td>
                        <td>{{ user?.lastname }}</td>
                        <td>{{ user?.email }}</td>
                        <td>
                            <div *ngFor="let selectedBreakoutGroup of user.selectedBreakoutGroups">
                                {{ selectedBreakoutGroup.key }}
                            </div>
                        </td>
                        <td>
                            {{ user?.assignedBreakoutGroup?.key }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
</div>
