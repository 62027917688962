export class PermissionsConfig {
    static readonly PERMISSIONS = {
        AUTHENTICATED: {
            andScopes: ["authenticated"],
            orScopes: []
        },
        GLOBAL: {
            // User may switch organizations
            andScopes: ["authenticated", "admin.organization.read", "global"],
            orScopes: []
        },
        "MENU.USERMANAGEMENT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.user.read", "admin.role.read", "admin.organization.read"]
        },
        "MENU.USERMANAGEMENT.USERS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.user.read"]
        },
        "MENU.USERMANAGEMENT.ROLES": {
            andScopes: ["authenticated"],
            orScopes: ["admin.role.read"]
        },
        "MENU.USERMANAGEMENT.ORGANIZATIONS": {
            andScopes: ["authenticated", "admin.organization.read", "global"],
            orScopes: []
        },
        "MENU.LANGUAGES": {
            andScopes: ["authenticated"],
            orScopes: ["admin.language.read"]
        },
        "MENU.REGISTRATION": {
            andScopes: ["authenticated"],
            orScopes: ["admin.registrationconfig.read"]
        },
        "MENU.MAIL": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailaccount.read", "admin.mailtemplate.read"]
        },
        "MENU.MAIL.MAILACCOUNTS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailaccount.read"]
        },
        "MENU.MAIL.MAILTEMPLATES": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailtemplate.read"]
        },
        "MENU.SYSTEMHEALTH": {
            andScopes: ["authenticated"],
            orScopes: ["admin.systemlog.read", "admin.systemjob.read", "admin.systemresource.read", "admin.systemerror.read"] // TODO: add scopes from the other modules
        },
        "MENU.SYSTEMHEALTH.SYSTEMLOGMESSAGES": {
            andScopes: ["authenticated"],
            orScopes: ["admin.systemlog.read"]
        },
        "MENU.SYSTEMHEALTH.SYSTEMJOBPERFORMANCES": {
            andScopes: ["authenticated"],
            orScopes: ["admin.systemjob.read"]
        },
        "MENU.SYSTEMHEALTH.SYSTEMRESOURCESNAPSHOTS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.systemresource.read"]
        },
        "MENU.SYSTEMHEALTH.SYSTEMERRORS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.systemerror.read"]
        },
        "MENU.BREAKOUTGROUPS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.breakoutgroup.read"]
        },
        "USERMANAGEMENT.USERS.ADD": {
            andScopes: ["authenticated"],
            orScopes: ["admin.user.create"]
        },
        "USERMANAGEMENT.USERS.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.user.read"]
        },
        "USERMANAGEMENT.USERS.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.user.update"]
        },
        "USERMANAGEMENT.USERS.VIEW": {
            andScopes: ["authenticated"],
            orScopes: ["admin.user.read"]
        },
        "USERMANAGEMENT.USERS.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.user.delete"]
        },
        "USERMANAGEMENT.ROLES.ADD": {
            andScopes: ["authenticated"],
            orScopes: ["admin.role.create"]
        },
        "USERMANAGEMENT.ROLES.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.role.read"]
        },
        "USERMANAGEMENT.ROLES.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.role.update"]
        },
        "USERMANAGEMENT.ROLES.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.role.delete"]
        },
        "USERMANAGEMENT.ORGANIZATIONS.ADD": {
            andScopes: ["authenticated", "global"],
            orScopes: ["admin.organization.create"]
        },
        "USERMANAGEMENT.ORGANIZATIONS.LIST": {
            andScopes: ["authenticated", "global"],
            orScopes: ["admin.organization.read"]
        },
        "USERMANAGEMENT.ORGANIZATIONS.EDIT": {
            andScopes: ["authenticated", "global"],
            orScopes: ["admin.organization.update"]
        },
        "USERMANAGEMENT.ORGANIZATIONS.DELETE": {
            andScopes: ["authenticated", "global"],
            orScopes: ["admin.organization.delete"]
        },
        "LANGUAGES.ADD": {
            andScopes: ["authenticated"],
            orScopes: ["admin.language.create"]
        },
        "LANGUAGES.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.language.read"]
        },
        "LANGUAGES.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.language.update"]
        },
        "LANGUAGES.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.language.delete"]
        },
        "MAIL.MAILTEMPLATES.ADD": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailtemplate.create"]
        },
        "MAIL.MAILTEMPLATES.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailtemplate.read"]
        },
        "MAIL.MAILTEMPLATES.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailtemplate.update"]
        },
        "MAIL.MAILTEMPLATES.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailtemplate.delete"]
        },
        "MAIL.MAILACCOUNTS.ADD": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailaccount.create"]
        },
        "MAIL.MAILACCOUNTS.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailaccount.read"]
        },
        "MAIL.MAILACCOUNTS.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailaccount.update"]
        },
        "MAIL.MAILACCOUNTS.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.mailaccount.delete"]
        },
        "REGISTRATIONCONFIG.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.registrationconfig.update"]
        },
        "SYSTEMHEALTH.SYSTEMLOGMESSAGES.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.systemlog.read"]
        },
        "SYSTEMHEALTH.SYSTEMJOBPERFORMANCES.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.systemjob.read"]
        },
        "SYSTEMHEALTH.SYSTEMRESOURCESNAPSHOTS.STATISTICS": {
            andScopes: ["authenticated"],
            orScopes: ["admin.systemresource.read"]
        },
        "SYSTEMHEALTH.SYSTEMERRORS.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.systemerror.read"]
        },
        "SYSTEMHEALTH.SYSTEMERRORS.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.systemerror.update"]
        },
        "BREAKOUTGROUPS.ADD": {
            andScopes: ["authenticated"],
            orScopes: ["admin.breakoutgroup.create"]
        },
        "BREAKOUTGROUPS.LIST": {
            andScopes: ["authenticated"],
            orScopes: ["admin.breakoutgroup.read"]
        },
        "BREAKOUTGROUPS.EDIT": {
            andScopes: ["authenticated"],
            orScopes: ["admin.breakoutgroup.update"]
        },
        "BREAKOUTGROUPS.DELETE": {
            andScopes: ["authenticated"],
            orScopes: ["admin.breakoutgroup.delete"]
        }
    };
}
