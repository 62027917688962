import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { DataTablesModule } from "angular-datatables";

import { BreakoutGroupsComponent } from "./breakoutgroups.component";
import { BreakoutGroupsService, BreakoutGroupConfigService, IBreakoutGroupConfigService } from "./breakoutgroups.service";
import { BreakoutGroupDetailComponent } from "./breakoutgroupdetail.component";
import { TranslateModule } from "@ngx-translate/core";
import { OAuthModule } from "@impacgroup/angular-oauth-base";
import { Route, RouterModule } from "@angular/router";
import { BreakoutGroupsMainComponent } from "./breakoutgroups.main.component";

@NgModule({
    declarations: [BreakoutGroupsMainComponent, BreakoutGroupsComponent, BreakoutGroupDetailComponent],
    imports: [CommonModule, RouterModule, FormsModule, DataTablesModule, BsDropdownModule, ModalModule, NgSelectModule, TranslateModule, OAuthModule],
    exports: [],
    providers: []
})
export class BreakoutGroupsModule {
    static forRoot(breakoutGroupConfig: IBreakoutGroupConfigService): ModuleWithProviders<BreakoutGroupsModule> {
        // returns the services as singletons throughout the app (for lazy loading purposes)
        return {
            ngModule: BreakoutGroupsModule,
            providers: [
                BreakoutGroupsService,
                {
                    provide: BreakoutGroupConfigService,
                    useValue: breakoutGroupConfig
                }
            ]
        };
    }

    static getRoutes(): Route[] {
        return [
            {
                path: "",
                component: BreakoutGroupsMainComponent,
                children: [
                    {
                        path: "",
                        component: BreakoutGroupsComponent
                    },
                    {
                        path: "add",
                        component: BreakoutGroupDetailComponent
                    },
                    {
                        path: ":id",
                        component: BreakoutGroupDetailComponent
                    }
                ]
            }
        ];
    }
}
